import React from 'react';
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import AppWithGlobalState from './state/AppWithGlobalState';
import Routes from './components/layout/MainLayoutWithRoutes'
import Auth from './auth/Auth';
import config from './config/config';

const client = new ApolloClient({
  uri: config.GraphQLURI,
  request: operation => {
    operation.setContext((context: { headers: any; }) => ({
      headers: {
        ...context.headers,
        authorization: Auth.getIdToken(),
      },
    }))
  }
});

function App() {
  return (
    <AppWithGlobalState>
      <ApolloProvider client={client}>
        <Routes />
      </ApolloProvider>
    </AppWithGlobalState>
  );


}

export default App;

