const routeDefinitions = {
  home: '/',
  login: '/login',
  profile: '/profile',
  privacyPolicy: '/privacy-policy',
  completedProblems: '/completed-problems',
  atomicStructure: {
    bohrModels: '/bohr-models'
  },
  periodicTable: {
    sortingIntoGroups: '/pt-groups'
  },
  ionicBonding: {
    ionicBondingPuzzles: '/puzzles',
    ionicBondingFormulas: '/ionic-bonding-formulas'
  },
  covalentBonding: {
    lewisDotStructures: '/lewis-dot-structures',
    covalentBonding: '/covalent-structures'
  },
};
export default routeDefinitions;
