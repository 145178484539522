import React from "react";
import { Button } from 'semantic-ui-react';
import auth0Client from '../../auth/Auth';

const LoginButton = (props) => {
  const signOut = () => {
    auth0Client.signOut();
    props.history.replace('/');
  };

  const isAuthenticated = auth0Client.isAuthenticated();

  return (
    <div>
      {!isAuthenticated && (
        <Button
          color="black"
          onClick={auth0Client.signIn}
        >
          Log in
        </Button>
      )}

      {isAuthenticated && <Button color="black" onClick={() => signOut()}>Log out</Button>}
    </div>
  );
};

export default LoginButton;
