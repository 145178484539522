// window.location.pathname

let redirect = 'https://grokchemistry.com/callback';
if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_BUILD_ENV === 'prod') {
  redirect = 'https://grokchemistry.com/callback';
} else if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_BUILD_ENV === 'dev') {
  redirect = 'https://dev.grokchemistry.com/callback';
} else if (process.env.NODE_ENV === 'development') {
  redirect = 'http://localhost:3000/callback'
}

const configObject = {
  auth0Domain: 'grokchemistry.auth0.com',
  auth0ClientId: '7Srdrkn3slX160dSlsMuAZgwJ3xxaJEq',
  auth0Redirect: redirect,
  GraphQLURI: process.env.NODE_ENV === 'development' ? 'http://localhost:4000/api' : 'https://grokchemistry.com/api',
};

export default configObject;
