import React from "react";
import auth0Client from '../../auth/Auth';

const Profile = () => {
  const isAuthenticated = auth0Client.isAuthenticated();
  const user = auth0Client.getProfile();

  if (!isAuthenticated) {
    return <div>Must be logged in.</div>;
  }

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="conProfile">
      <div className="grokCard">
        <img src={user.picture} width={50} alt="Profile" />
        <h2>{user.name}</h2>
        <p>{user.email}</p>
      </div>
    </div>
  );
};

export default Profile;
