import React from 'react';

class Login extends React.Component {
  render() {
    return (
      <div
        className="pageWrapper"
      >
        Login
      </div>
    )
  }
}
export default Login;
