import React from 'react';
import moment from 'moment';
import { Query } from "react-apollo";
import { Container, Grid } from 'semantic-ui-react';

// import ConfirmModal from '../shared/ConfirmModal';
// import { clearAllSavedProblems } from '../../utils/saveProblem';
import problemTypes from '../../utils/problemTypeConstants';
import withState from '../../state/withState';
import { GET_PRACTICE_RESULTS_BY_USER_ID } from '../../gql/queries/queries';
import auth0Client from '../../auth/Auth';

function CompletedProblems(props) {

  const isAuthenticated = auth0Client.isAuthenticated();
  const user = auth0Client.getProfile();

  if (!isAuthenticated) {
    return (
      <div
        className="pageWrapper"
      >
        Please log in in order to view your saved results.
      </div>

    )
  }

  if (!user) {
    return <div>Loading...</div>
  }

  return (
    <div
      className="pageWrapper"
    >
      <Container>
        <h2>Completed Problems</h2>

        {/*<Button*/}
        {/*  color="red"*/}
        {/*  onClick={() => this.setState({ deleteModalOpen: true })}*/}
        {/*>*/}
        {/*  Delete Saved Problems*/}
        {/*</Button>*/}

        {/*<Button*/}
        {/*  onClick={() => props.AppState.doAnAsyncThing()}*/}
        {/*>*/}
        {/*  Global thing*/}
        {/*</Button>*/}

        <Grid className="grokCard">
          <Grid.Row columns={4}>
            <Grid.Column>
              <strong>Type</strong>
            </Grid.Column>

            <Grid.Column>
              <strong>Name</strong>
            </Grid.Column>

            <Grid.Column>
              <strong>Date</strong>
            </Grid.Column>

            <Grid.Column>
              <strong>Times Checked</strong>
            </Grid.Column>
          </Grid.Row>

          <Query
            query={GET_PRACTICE_RESULTS_BY_USER_ID}
            variables={{ userId: user.sub }}
            fetchPolicy="network-only"
          >
            {({ loading, error, data }) => {
              if (loading) return null;
              if (error) return `Error! ${error}`;


              return JSON.parse(data.getPracticeResultsByUserId).map(problem => {
                  return (
                    <Grid.Row columns={4} key={problem.id}>
                      {/*Problem Type*/}
                      <Grid.Column
                      >
                        {problemTypes[problem.typeId].name}
                      </Grid.Column>

                      {/*Problem Name*/}
                      <Grid.Column
                      >
                        {problem.problem.name}
                      </Grid.Column>

                      {/*Date completed*/}
                      <Grid.Column
                      >
                        {moment(problem.timestamp).format('MMMM DD, YYYY')}
                      </Grid.Column>

                      {/*Number of Times Checked*/}
                      <Grid.Column
                      >
                        {problem.numberOfTimesChecked}
                      </Grid.Column>
                    </Grid.Row>
                  )
                })
            }}
          </Query>

          {/*{*/}
          {/*  dbSavedProblems.map(problem => {*/}
          {/*    return (*/}
          {/*      <Grid.Row columns={4} key={problem.id}>*/}
          {/*        /!*Problem Type*!/*/}
          {/*        <Grid.Column*/}
          {/*        >*/}
          {/*          {problemTypes[problem.typeId].name}*/}
          {/*        </Grid.Column>*/}

          {/*        /!*Problem Name*!/*/}
          {/*        <Grid.Column*/}
          {/*        >*/}
          {/*          {problem.problem.name}*/}
          {/*        </Grid.Column>*/}

          {/*        /!*Date completed*!/*/}
          {/*        <Grid.Column*/}
          {/*        >*/}
          {/*          {moment(problem.timestamp).format('MMMM DD, YYYY')}*/}
          {/*        </Grid.Column>*/}

          {/*        /!*Number of Times Checked*!/*/}
          {/*        <Grid.Column*/}
          {/*        >*/}
          {/*          {problem.numberOfTimesChecked}*/}
          {/*        </Grid.Column>*/}
          {/*      </Grid.Row>*/}
          {/*    )*/}
          {/*  })*/}
          {/*}*/}
        </Grid>
      </Container>

      {/*<ConfirmModal*/}
      {/*  open={this.state.deleteModalOpen}*/}
      {/*  title="Delete Completed Problems"*/}
      {/*  confirmButtonLabel="Delete"*/}
      {/*  cancelButtonLabel="Cancel"*/}
      {/*  confirmText="Are you sure that you'd like to delete your completed problems?"*/}
      {/*  confirmButtonColor="red"*/}
      {/*  onConfirm={() => this.clearAllSavedProblems()}*/}
      {/*  onCancel={() => this.setState({ deleteModalOpen: false })}*/}
      {/*/>*/}
    </div>

  )


}
const CompletedProblemsWithState = withState(CompletedProblems);
export default CompletedProblemsWithState
// export default compose(
//   graphql(
//     GET_PRACTICE_RESULTS_BY_USER_ID,
//     {
//       options: {
//         fetchPolicy: 'network-only',
//         variables: {
//           userId: getUserIdFromLocalStorage()
//         }
//       }
//     },
//   ),
//   graphql(ANION_QUERY, { name: 'anions' }),
// )(CompletedProblemsWithState);


